.Dividers {
    width: 0.8px;
    height: 419px;
    background: var(--Gable-Green, #1c2e36);
}

@media (max-width: 960px) {


    .Dividers {
        width: 100%;
        height: 0.8px;
        transform: rotate(0deg);

    }
}
 .responsive-label {
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     font-family: Mulish;
     font-size: 1rem;
     font-style: normal;
     font-weight: 400;
     line-height: 170%;
     color: var(--Gable-Green, #1C2E36);
     width: 290px;
 }

 @media (max-width: 600px) {
     .responsive-label {
         width: 100%;
     }
 }

 




 .responsive-text {
     margin-left: 25px
 }

 @media (max-width: 600px) {
     .responsive-text {
         
         font-size: 10px;
      
     }
 }