@import "../../../../styles/variables.scss";

.user-management {
    background-color: $lightSkyBlue;
    align-items: "center";
    // padding: 24px 24px 24px 24px;

    .user-management-content {
        background-color: $backGroundColor;
        // margin-left: 24px;
        // height: auto;
    }
}

.custom-popover {
    width: 200px;
    height: auto;
}

.Card-Content {
    width: 15rem;
    height: 12rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.profile-image {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.1875rem;
    background: #D9D9D9;
}