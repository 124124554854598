@import "../../../styles/variables.scss";

.home-container {
    height: auto;
    overflow: hidden;

    .dashboard-mask-container {
        display: flex;
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(243, 249, 254, 0.9);
        z-index: 1;

        .content {
            display: flex;
            flex-direction: column;
            width: 60%;
            height: 50vh;
        }
    }
}