@import "../../../../styles/variables.scss";

.skills-overview-container {
    .overview-mask-container {
        display: flex;
        position: absolute;
        height: 100vh;
        background-color: rgba(243, 249, 254, 0.9);
        z-index: 2;
        justify-content: center;

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            height: 50vh;
        }
    }
}