.Header {
    color: #000;


    font-family: Inter;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

}

.contents {
    display: flex;


    .left-section {
        flex: 1;
        width: 155;
        height: 195;


        .aspect {
            width: 155;
            height: 195;
        }
    }

    .right-section {
        flex: 1;

        padding: 10px;

        .organization-details {
            margin-bottom: 10px;

            .content {
                font-weight: bold;
            }

            .subtitle {
                color: #333;
            }
        }
    }

}

.organization-details {
    margin-bottom: 10px;

    .content {
        font-weight: bold;
    }

    .subtitle {
        color: #333;
    }
}

.plan-container {
    padding: 40px 24px;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    display: flex;

    &-card1 {
        flex-direction: column;
        border-radius: 4px;
        background: var(--White, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &-card2 {
        flex-direction: column;
        border-radius: 12px;
        background: var(--Macaw-Blue-Green, #25BCCA);
        box-shadow: 0px 10px 25px 0px #CCD9FF;
    }
}

.text-custom {
    color: var(--Macaw-Blue-Green, #25BCCA);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 22px;
}

.plan-bill-container {
    .plan-details {
      .MuiGrid-item {
        padding-left: 0px;
      }
    }
  }

