@import '../../../styles/variables.scss';

.custom-chart-container {
    height: 365px;
    min-width: 200px;
    max-width: 100%;
    padding: 24;
    border-radius: 16px;
    background-color: $chartColor;
    filter: drop-shadow(0.5px 3px 3px #00000040);
}