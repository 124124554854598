@import "../../../styles/variables.scss";

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .login-grid-container {
    background-color: #ffffff;
    padding: 3rem;
    border-radius: 5px;

    .divider-line {
      border: 1px solid $gableGreen;
      opacity: 1;
    }

    .triggered-otp {
      color: $caribbeanGreen;
    }
  }
}
.asterisk {
  color: $deepSkyBlue;
}


// @media screen and (-webkit-min-device-pixel-ratio: 0) {
//   .email-container {
//     margin-bottom: 40px;
//   }
// }

// @media screen and (min-width: 1400px) {
//   .email-container {
//     margin-bottom: 15px;
//   }
// }