.container-fluid {
    background-color: rgba(243, 249, 254, 1);
    padding: 16px;
}

.notication-container {
    padding: 0px 30px 0px 30px;
    max-width: 1312px;
    max-height: 500px;
    margin: 0 auto;
    background-color: #fff;
    overflow: auto;
}

.notification-sub-container {
    display: flex;
    justify-content: space-between;
}

.notification-dividers {
    margin: 0 auto;
    width: 1080px;
    height: 0.1px;
    border: 0.4px solid #1C2E36;
    transform: rotate(0deg);
}

.no-notification {
    margin: 10px;
}

.heading {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-left: 8px;
}