.contents {
  display: flex;

  .css-wqan3a-MuiGrid-root {
    max-width: 12.666667%;
  }

  .left-section {
    flex: 1;
    width: 155;
    height: 195;
  }

  .right-section {
    flex: 1;

    .organization-details {
      margin-bottom: 30px;

      .content {
        font-weight: bold;
        color: var(--Gable-Green, #1c2e36);
        font-family: Helvetica;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        width: 240px;
        height: 25px;
        flex-shrink: 0;
      }

      .subtitle {
        color: #333;
      }
    }
  }
}

.organization-details {
  margin-bottom: 30px;

  .content {
    font-weight: bold;
    color: var(--Gable-Green, #1c2e36);
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
  }

  .subtitle {
    color: #333;
  }
}

.image-section-container {
  position: relative;

  .image-container {
    position: relative;
    display: inline-block;
  }

  .image {
    width: 150px;
    height: 160px;

  }

  .file-input {
    display: none;
  }
}