@import "../../../../styles/variables.scss";

.dashboard-info-container {
  padding: 24px;
  border-radius: 15px;

  &.green {
    background-color: $iceBerg;
  }

  &.blue {
    background-color: $linkWater;
  }

  &.harp {
    background-color: $harp;
  }
  &.crystalBlue {
    background-color: $crystalBlue;
  }

  .title-text {
    font-size: 12px;
    color: $riverBed;
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .name-text {
    font-size: 18px;
    color: $gableGreen;
  }
  .sub-name-text {
    font-size: 12px;
    color: #1C1C1C;
  }
}
