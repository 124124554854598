@import "../../../styles/variables.scss";

.onboard-organisation {
    background-color: $lightSkyBlue;
    align-items: "center";
    padding: 24px 24px 24px 24px;

    .onboard-organisation-content {
        background-color: $backGroundColor;
        margin-left: 24px;
    }
}

.divider {
    width: 1px;
        height: 80%;
        border: 1px solid #1c2e36;
       margin-top: 90px;
        margin-bottom: 30px;
}     

@media (max-width: 900px) {
    .divider {
       
        width: 100%;
            height: 1px;
            border: 1px solid #1c2e36;
            transform: rotate(0deg);
    }
}
.DeleteIcon {
    color:$brinkPink
}