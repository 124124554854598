@import "../../../styles/variables.scss";

.menu-container {
  display: flex;
  font-size: 14px;
  a {
    color: black;
    text-decoration: none;
    margin-left: 1.5rem;
    padding-bottom: 0.5rem;

    &.active {
      color: $appPrimaryColor;
      border-bottom: 3px solid $appPrimaryColor;
    }
  }
}
