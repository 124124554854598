@import "../../../../styles/variables.scss";

.user-list-container {
    .upload-box-container {
        margin: 1rem;
        border: 2px dashed $hitGrey;
        border-radius: 8px;
        padding: 2rem 1rem;
        background: rgba(250, 250, 250, 0.7);
        width: 80%;

        .file-upload-icon {
            border-radius: 12px;
            padding: 0.5rem 1.5rem;
            background: $linkWater;
            cursor: pointer;
        }
    }
}