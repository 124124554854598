@import "../../../../../styles/variables.scss";

.intro-workflow-container {
  .select-field {
    .MuiSelect-icon {
      color: $appPrimaryColor;
      right: 20px;
    }
  }
}
