.sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 15vw;


}

.successful-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 15vw;
  height: 100%;

}

.subContainer {
  display: flex;
  flex-direction: column;

  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
}

.signUpContainer {
  display: flex;
  flex-direction: row;

  border-radius: 4px;
  margin: 40px;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  margin-right: 45px;
}

.logo-container {
  width: 120px;
  height: 40px;
  top: 150px;
  left: 240px;
  margin-bottom: 24px;
}

.sub-content {
  flex-wrap: wrap;
}

.sign-up-form-container {

  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.text-fields-container {
  display: flex;
  flex-direction: column;
}

.Dividers {
  width: 0.3px;
  height: 80%;
  margin-top: 50px;
  border: 1px solid rgba(28, 46, 54, 1);

}

@media (max-width: 900px) {
  .Dividers {
    width: 100%;
    height: 0.3px;
    border: 1px solid rgba(28, 46, 54, 1);
    transform: rotate(0deg);
  }
}


.successful-right-side-container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successful-heading {
  width: 336px;
  height: 36px;
  top: 294px;
  left: 800px;
}

.company-logo-image-successful-container {
  width: 195px;
  height: 195px;
  top: 410px;
  left: 888px;
  margin: 45px;
}

.heading6 {

  display: flex;
  justify-content: center;
  align-items: center;
}

.successful-content {
  width: 400px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bodySmall-content-successful {
  line-height: 23.8px;
  width: 400px;
  height: 48px;
  top: 682px;
  left: 800px;
  align-items: center;
}

.LoinHereContainer {
  width: 151px;
  height: 48px;
  top: 362px;
  left: 240px;
  margin-top: 25px;
}

.LoginContent {
  width: 151px;
  height: 48px;
  top: 362px;
  left: 240px;
  line-height: 20.8px;
  letter-spacing: 0em;
}

.LoginLink {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 900;
  line-height: 22.8px;
  font-style: "normal";
  text-decoration: "underline";
}

@media screen and (max-width: 768px) {
  .sign-up-container {
    padding: 10% 5%;
  }

  .successful-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10vh 15vw;
    height: 100%;

  }

  .subContainer,
  .signUpContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 auto;
  }

  .subContainer {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
    overflow: auto;
  }

  .signUpContainer {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin: 40px 10px;
  }

  .sign-up-form-container {

    display: flex;
    flex-direction: column;
  }

  .heading {
    width: 182px;
    height: 36px;

  }

  .leftSideContainer {
    display: flex;
    flex-direction: column;
    width: 100%;


    margin: 40;
  }

  .text-fields-container {
    width: 100%;

  }

  .successful-right-side-container {
    width: 100%;
    margin-top: 24px;
    align-items: center;
  }

  .successful-heading {
    margin-bottom: 24px;
  }

  .company-logo-image-successful-container {
    margin: 24px 0;
  }

  .heading6 {
    margin-bottom: 24px;
  }

  .successful-content {

    text-align: center;
  }
}

@media screen and (max-width: 768px) {


  .successful-right-side-container {
    width: 100%;
    margin-top: 24px;
    align-items: center;
  }

  .successful-heading {
    margin-bottom: 24px;
  }

  .company-logo-image-successful-container {
    margin: 24px 0;
  }

  .heading6 {
    margin-bottom: 24px;
  }

  .successful-content {

    text-align: center;
  }
}