.request-up-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .subContainer {
    background: rgba(255, 255, 255, 1);
    padding: 3rem;
    border-radius: 15px;

    .leftSideContainer {
      display: flex;
      flex-direction: column;
    }
  }
}

.Dividers {
  width: 0.3px;
  height: 80%;
  margin-top: 50px;
  border: 1px solid rgba(28, 46, 54, 1);
}

@media (max-width: 900px)  {
  .Dividers {
    width: 100%;
    height: 0.3px;
    border: 1px solid rgba(28, 46, 54, 1);
    transform: rotate(0deg);
  }
}

/* @media screen and (max-width: 768px) {
  .request-up-container {
    padding: 10% 5%;
  }

  .subContainer,
  .requestUpContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 auto;
  }

  .subContainer {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
    overflow: auto;
  }

  .requestUpContainer {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin: 40px 10px;
  }

  .leftSideContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 40;
  }
} */
