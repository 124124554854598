@import "../../../styles/variables.scss";

.manage-organisation-container {
    background-color: #f3f9fe;

    .table-container {
        background-color: #fff;
    }

    .pagination-container {
        p {
            margin-top: 1rem;
        }
    }
}