@import "../../../styles/variables.scss";

.workflow-container {
  background-color: #f3f9fe;
  .table-container {
    background-color: #fff;
  }

  .pagination-container {
    p {
      margin-top: 1rem;
    }
  }
}
