$backGroundColor: #fdfffe;
$appPrimaryColor: #0084f4;
$iceBerg: #d5f2ea;
$linkWater: #d5e9fa;
$gableGreen: #1c2e36;
$hitGrey: #a3b1ae;
$harp: #e6edef;
$brinkPink: #ff647c;
$deepSkyBlue: #0084f4;
$lightSkyBlue: #f3f9fe;
$caribbeanGreen: #00c48c;
$white: #ffffff;
$riverBed: #45545A;
$harpLight: #fafbfc;
$crystalBlue: #66B5F8;
$chartColor: #F7F9FB;

