@import "../../../../styles/variables.scss";
.user-dividers {
    width: 1px;
    height: 100%;
    border: 0.5px solid #1c2e36;
    transform: rotate(0deg);
}

@media (max-width: 900px) {
    .user-dividers {
        width: 100%;
        height: 1px;
        border: 0.5px solid #1c2e36;
        transform: rotate(0deg);
    }
}

.upload-box-container {
    margin: 1rem;
    border: 2px dashed $hitGrey;
    border-radius: 8px;
    padding: 2rem 1rem;
    background: rgba(250, 250, 250, 0.7);
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  
    .file-upload-icon {
        border-radius: 12px;
        padding: 0.5rem 1.5rem;
        background: $linkWater;
        cursor: pointer;
    }
}