@import "../../../../../styles/variables.scss";

.skills-table-container {
  background-color: $harpLight;
  border-radius: 1rem;
  padding: 1rem 1.6rem;

  .skills-added-month {
    display: flex;
    align-items: center;
    font-size: 14px;

    .count-text {
      color: $hitGrey;
      font-weight: 700;
    }

    .month-text {
      color: $hitGrey;
      font-weight: 400;
    }
  }

  .skills-table {
    // max-height: 21.8rem;

    .MuiTableCell-head {
      font-size: 10px;
    }

    .skills-name-text {
      display: flex;
      align-items: center;
    }

    .header {
      font-style: normal;
      font-weight: 700;
      line-height: 80%;
    }

    .MuiTableCell-body {
      font-size: 14px;
      font-weight: 700;
      border-bottom: 1px solid #d6e1e4;
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
}