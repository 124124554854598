@import "../../../styles/variables.scss";

.spline-chart-container {
  background-color: #F7F9FB;
  border-radius: 1rem;
  padding: 1rem 1.2rem;
  filter: drop-shadow(0.5px 3px 3px #00000040);
}
.chart-container-title {
  cursor: pointer;
}