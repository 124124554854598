@import "../../../styles/variables.scss";

.mobile-app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .mobile-app-grid-container {
        background-color: #ffffff;
        padding: 3rem;
        border-radius: 5px;

        .divider-line {
            border: 1px solid $gableGreen;
            opacity: 1;
        }
    }
}