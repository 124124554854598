@import "../../../../../../styles/variables.scss";

.scrollableContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 540px;
    min-height: auto;
    max-height: 125px;
    margin: 2px;
    overflow-y: auto;
}