@import "../../../../../styles/variables.scss";

.skills-time-spent-container {
  background-color: $harpLight;
  border-radius: 1rem;
  padding: 1rem 1.2rem;
  filter: drop-shadow(0.5px 3px 3px #00000040);

  .skills-time-spent-table {

    .MuiTableCell-head {
      font-size: 14px;
      font-weight: 500;
    }

    .skills-name-text {
      display: flex;
      align-items: center;
    }

    .header {
      font-style: normal;
      font-weight: 700;
      line-height: 80%;
    }

    .MuiTableCell-body {
      font-size: 14px;
      font-weight: 700;
      border-bottom: 1px solid #d6e1e4;
    }
  }
}