@import "../../../../styles/variables.scss";

.assign-container {
  background-color: rgba(230, 237, 239, 0.3);
  padding: 1.5rem 2.5rem;

  .search-name-text {
    font-size: 14px;
  }

  .assigned-user-container {
    height: 250px;
    overflow: hidden auto;
    scroll-behavior: smooth;

    .assigned-user {
      padding: 1rem 1rem;
      background-color: white;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
    }

    .linear-progress-container {
      .MuiLinearProgress-root {
        height: 5px;
        border-radius: 5px;
        background-color: $harp;

        .MuiLinearProgress-bar {
          border-radius: 5px;
          background-color: $brinkPink;
        }
      }
    }

    .vertical-line {
      border-left: 1px solid $gableGreen;
      opacity: 0.3;
    }
  }
}
