@import "../../../styles/variables.scss";
.icon {
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 0.9375rem 0rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
}

.ProgressLabel {
  display: flex;
  width: 20.5rem;
  height: 5rem;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 0.375rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
}

.progressIcon {
  display: flex;
  height: 80px;
  align-items: center;
  gap: 6px;
  padding-right: 20px;
}

.ProgressHeading {
  color: var(--River-Bed, #45545a);
  text-align: center;
  font-family: Mulish;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.bodyContainer {
  max-width: 1440px;
  // min-height: 520px;
  flex: 1;
  overflow: auto;
  padding: 30px;
  margin-bottom: 2rem;
}

.languagesContainer {
  width: 32.0625rem;
  height: 4.375rem;
  flex-shrink: 0;
  margin: 5;
  display: flex;
  flex-direction: column;
}

@media (max-width: 960px) {
  .UploadedContainer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .UploadedContainer {
    width: 100%;
  }
}

.UploadedContainer {
  @media (max-width: 600px) {
    width: 100%;
    padding: 12px;
  }
}

.UploadedContainer {
  display: flex;
  width: 403px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  #uplodedContent {
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8.66px;
    border: 1px dashed var(--Hit-Grey, #a3b1ae);
    background: rgba(250, 250, 250, 0.7);

    .uploadimageContainer {
      display: flex;
      width: 92px;
      height: 52px;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      background: var(--Link-Water, #d5e9fa);

      .file-upload-icon {
        border-radius: 12px;
        padding: 0.5rem 1.5rem;
        background: $linkWater;
        cursor: pointer;
        margin-left: 11px;
      }
    }

    @media (max-width: 600px) {
      padding: 24px;
    }
  }

  #uplodedContentWithImage {
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8.66px;
    background: rgba(250, 250, 250, 0.7);
    position: relative;
    box-shadow: 0px 1px 5px 0px rgba(100, 116, 139, 0.12);

    .uploadimageContainer {
      display: flex;
      width: 92px;
      height: 52px;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      background: var(--Link-Water, #d5e9fa);
      .file-upload-icon {
        border-radius: 12px;
        padding: 0.5rem 1.5rem;
        background: $linkWater;
        cursor: pointer;
        margin-left: 11px;
      }
    }
  }
  #uplodedContentForPdf {
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8.66px;
    background: rgba(250, 250, 250, 0.7);
    position: relative;
    border: 1px dashed $hitGrey;

    .uploadimageContainer {
      display: flex;
      width: 92px;
      height: 52px;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      background: $linkWater;
      .file-upload-icon {
        border-radius: 12px;
        padding: 0.5rem 1.5rem;
        background: $linkWater;
        cursor: pointer;
        margin-left: 11px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 24px;
  }
}

.contents {
  display: flex;

  .left-section {
    flex: 1;
    width: 155;
    height: 195;

    .aspect {
      width: 155;
      height: 195;
    }
  }

  .right-section {
    flex: 1;

    .organization-details {
      margin-bottom: 10px;

      .content {
        font-weight: bold;
      }

      .subtitle {
        color: #333;
      }
    }
  }
}

.organization-details {
  margin-bottom: 10px;

  .content {
    font-weight: bold;
  }

  .subtitle {
    color: #333;
  }
}

.footer {
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.3);
}

.Divider {
  width: 0.8px;
  height: 419px;
  background: var(--Gable-Green, #1c2e36);
}

.video-image {
  width: 147px;
  height: 90px;
  flex-shrink: 0;
  background: #ececee;
  display: "flex";

  img {
    object-fit: cover;
    width: 147px;
    height: 90px;
  }

  video {
    object-fit: cover;
    width: 147px;
    height: 90px;
  }
}

.upload-image-preview {
  width: 5.1875rem;
  height: 2.625rem;
  display: "flex";
  justify-content: center;
  margin-left: 10px;
  margin-top: 10px;
}

.content-number {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--Hit-Grey, #a3b1ae);
}

@media only screen and (max-width: 600px) {
  .bodyContainer {
    width: 100%;

    height: auto;

    padding: 10px;
  }
}

.contents {
  display: flex;
  flex-wrap: wrap;
}

.leftSection {
  width: 850px;
}

.rightSection {
  width: 590px;

  .MuiGrid-item {
    padding-left: 0px;
  }
}

.play-icon {
  width: 48px;
  height: 48px;
  margin-left: 15px;
}

.description-field {
  display: flex;
  width: 320px;
  padding: 14px 15px 12.857px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  // position: relative;

  .css-1h87d35-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
  }
}

.title-text {
  display: flex;
  width: 383px;
  // height: 86px;
  flex-direction: column;
  gap: 10px;
}

.select-field {
  display: flex;
  width: 329px;
  height: 55px;
}

.workflow-card {
  width: 335px;
  height: 100px;
  border-radius: 12px;
  box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
