@import "./variables.scss";

.label-grey {
  color: $hitGrey;
}

.bg-dark-blue {
  background-color: $linkWater;
}

.bg-light-blue {
  background-color: $lightSkyBlue;
}
.bg-harp {
  background-color: $harp;
}

a:not([disabled]) {
  cursor: pointer;
}

.bg-white {
  background-color: $white;
}