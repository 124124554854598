@import "../../../../styles/variables.scss";

.organisation-team-container {
  background-color: $lightSkyBlue;
  height: 100vh;

  .organisation-team {
    background-color: $harp;
    border-radius: 8px;
    padding: 1rem;

    .team-management {
      background-color: white;
      border-radius: 8px;
      padding: 1.5rem 1rem;
    }
  }

  .organisation-management {
    background-color: "#EAEFFF";
    /* For browsers that do not support gradients */
    background: linear-gradient(to right, #eaefff, #faf9ff);
    border-radius: 14px;
    padding: 1rem 1.5rem;
    height: 100%;

    .vl-radius {
      border-right: 4px solid $deepSkyBlue;
      border-radius: 0px 4px 4px 0px;
      margin-left: -24px;
      padding-bottom: 8px;
    }

    .team-avatar-container {
      .MuiAvatarGroup-avatar {
        width: 28px;
        height: 28px;
        border: none;
      }

      .MuiAvatar-colorDefault {
        background-color: $deepSkyBlue;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.ellipsis-text {
  width: 'auto';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}